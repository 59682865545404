import React, { createRef, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import * as moment from 'moment-timezone';

import { Datatable } from "../../../shared/datatable/Datatable";
import { runAssessmentList } from "../../../shared/settings/apiConfig";
import { TextInput } from "../../../shared/form/FormInput";

import './RunAssessmentList.scss';
import { Validators } from "../../../shared/utils/Validators";
import InputGroupText from "react-bootstrap/esm/InputGroupText";

const runAssessmentFilters = {
    'clientId': { field: 'clientid', value: '', op: '=', 'join': 'AND' },
    'runStatus': { field: 'runstatus', value: '', op: '=', 'join': 'AND' },
};

export const RunAssessmentList = (props) => {

    const dataTable = createRef();

    const customDate = (dataValue) => {
        if (dataValue) {
            let date = moment.utc(dataValue).local();
            return `${date.format('MM/DD/YYYY hh:mm A')} ${moment.tz(moment.tz.guess()).zoneAbbr()}`;
        } else
            return '- - -';
    };

    const [config, setConfig] = useState({
        name: 'assessment',
        url: runAssessmentList,
        columns: [
            // {
            //     name: '', property: '', columnType: 'actions', width: '150px',
            //     actions: {
            //         header: <Button className="save-button" onClick={() => { navigateToRunAssessment() }}>+ Create</Button>,
            //         columns: []
            //     }
            // },
            { name: 'Client ID', property: 'clientid', sort: true, width: '200px' },
            { name: 'Run Date', property: 'rundate', sort: true, width: '250px', customColumn: customDate },
            { name: 'Scheduled Date', property: 'scheduleddate', sort: true, width: '250px', customColumn: customDate },
            { name: 'Run Status', property: 'runstatus', sort: true, width: '200px' },
            { name: 'Start Time', property: 'starttime', sort: true, width: '250px' },
            { name: 'End Time', property: 'endtime', sort: true, width: '250px' },
        ],
        field: ["id", "rundate", "scheduleddate", "starttime", "endtime", "runstatus", "clientid", "createddate", "modifieddate"],
        filter: [],
        sort: [{ "field": "id", "direction": "asc" }],
        pagination: true,
    });

    const [runAssessmentSearchForm, setRunAssessmentSearchForm] = useState({
        'clientId': { value: '', valid: true, error: '', validators: [] },
        'runStatus': { value: '', valid: true, error: '', validators: [] },
    });

    const navigateToRunAssessment = () => {
        props.history.push('/run-assessments/add');
    }

    const onSearch = () => {
        let filters = [];
        const allFilters = { ...runAssessmentFilters };
        const controlKeys = Object.keys(runAssessmentSearchForm);
        controlKeys.forEach(control => {
            if (runAssessmentSearchForm[control].value) {
                filters.push({ ...allFilters[control], value: runAssessmentSearchForm[control].value });
            }
        });

        if (filters.length > 0) {
            dataTable.current.searchData(filters);
        }
    };

    const onClear = () => {
        setRunAssessmentSearchForm(prevState => {
            const formData = { ...prevState };
            const keys = Object.keys(formData);
            keys.forEach(key => {
                formData[key].value = "";
            });

            return formData;
        });

        dataTable.current.searchData([]);
    };

    const setValue = (controlName, event) => {
        setRunAssessmentSearchForm(prevState => {
            return {
                ...prevState,
                [controlName]: Validators.validateFormControl(runAssessmentSearchForm[controlName], event.target.value)
            }
        });
    }

    return (
        <div className="assessment-list  ">
            <div className="p-3">
                <div className="py-3 ">
                <InputGroup >
                    <InputGroupText className="border-primary border-1" >
                        <i class="fa fa-search text-primary" ></i>
                    </InputGroupText>
                    <span class="icon-envelope-o"></span>
                    <Form.Control  className="searchinput w-40" name={'clientId'} placeholder={'Client Id'}
                        value={runAssessmentSearchForm.clientId.value} error={runAssessmentSearchForm.clientId.error} onChange={(event) => setValue('clientId', event)} />
                    <Form.Control className="searchinput w-40" name={'runStatus'} placeholder={'Run Status'}
                        value={runAssessmentSearchForm.runStatus.value} onChange={(event) => setValue('runStatus', event)} />
                    <InputGroupText role={"button"} className="border border-1 border-primary  bg-secondary" onClick={onClear}>
                        <div className="">Clear All</div>
                        {/* <i class="fa fa-search" ></i> */}
                    </InputGroupText>
                    <InputGroupText role={"button"} className="border border-1 border-primary  bg-primary" onClick={onSearch}>
                        <div className="text-white">Search</div>
                        {/* <i class="fa fa-search" ></i> */}
                    </InputGroupText>
                </InputGroup>
                </div>
            </div>
            {/* <div className="d-flex search-container" style={{}}>
                <Row>
                    <TextInput name={'clientId'} label={'Client Id'} placeholder={'Client Id'}
                        value={runAssessmentSearchForm.clientId.value} error={runAssessmentSearchForm.clientId.error} onChange={(event) => setValue('clientId', event)} />
                    <TextInput name={'runStatus'} label={'Run Status'} placeholder={'Run Status'}
                        value={runAssessmentSearchForm.runStatus.value} onChange={(event) => setValue('runStatus', event)} />
                </Row>
                <div className="d-flex" style={{ gap: '10px', justifyContent: 'flex-end' }}>
                    <button type="button" className="btn btn-outline-primary" onClick={onClear}>Clear All</button>
                    <button type="button" className="btn btn-primary" onClick={onSearch}>Search</button>
                </div>
            </div> */}
            <div style={{ padding: '15px' }}>
                <Datatable ref={dataTable} config={config} />
            </div>
            <div className="d-flex create-button  align-items-center justify-content-end">
                    <Button className="bg-primary" onClick={()=>{navigateToRunAssessment()}}>+ Create</Button>
                </div>
        </div>
    );
};
