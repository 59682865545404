import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal, Tab, Tabs } from "react-bootstrap";

import { getRandomChars } from "../../../utils/utils";
import { getApi, postApi } from "../../../shared/services/apiHelper";
import { assessmentAdd, assessmentDetail } from "../../../shared/settings/apiConfig";
import { DropdownInput, DropdownInputGroup, TextInput } from "../../../shared/form/FormInput";
import Toast from 'react-bootstrap/Toast';

import './AssessmentAdd.scss';
import ReactQuill from "react-quill";
const scoringCredit = [
    {
        id: 1,
        value: "Score"
    },
    {
        id: 2,
        value: "Weightage"
    },
    {
        id: 3,
        value: "Question"
    }
];
const operators = [
    {
        id: "==",
        value: "=="
    },
    {
        id: ">",
        value: ">"
    },
    {
        id: "<",
        value: "<"
    },
    {
        id: ">=",
        value: ">="
    },
    {
        id: "<=",
        value: "<="
    }
];

const linkType = [

    {
        id: "When the choice/answer is",
        value: "When the choice/answer is",
    },
    {
        id: "Always",
        value: "Always",
    },
]
const questionlinkType = [
    {
        id: "Goto question",
        value: "Goto question",
    },
    {
        id: "End the assessment",
        value: "End the assessment",
    },
]
const questionTypes = [

    {
        id: 1,
        value: "Multi Choice",
    },
    {
        id: 2,
        value: "True or False",
    },
    {
        id: 3,
        value: "Descriptive",
    },
    // {
    //     id: 4,
    //     value: "Audio/Visual",
    // },
    {
        id: 5,
        value: "Rating Based",
    },
    {
        id: 6,
        value: "Reorder",
    }
];

const noOptions = [3, 4, 5];
const complexity = ["level 1", "level 2", "level 3"]

const IMAGE_TYPES = 'image/gif, image/jpeg, image/png, image/bmp, image/heic, image/tiff';

const FILE = '.pdf,';
const getDefaultKpiData = (indicatorName) => {
    return {
        indicatorname: indicatorName,
        configuration: [ getDefaultKpiConfigurationData('', '', '') ]
    }
};
const getDefaultKpiConfigurationData = (operator, score, displayName, color='#000000') => {
    return { "operator": operator, "score": score, "displayname": displayName, "color": color}
};

export const AssessmentAddFn = (props) => {

    const getDefaultOptionData = (optionName, score) => {
        return { optionname: optionName, score: score };
    };

    const getDefaultOptionSetData = (isDefaultSet = false, optionSetLength = null) => {
        return { isdefaultset: isDefaultSet, options: [getDefaultOptionData('', '')], setnumber: optionSetLength || 1 };
    };
    const getDefaultQuestionData = (questionName, hint, questionType, questionTypeName, questionMandatory, questionOrder) => {
        let optionSetData = getDefaultOptionSetData(true);
        return {
            questionname: questionName,
            hint: hint,
            questiontype: questionType,
            questiontypename: questionTypeName,
            mandatory: questionMandatory,
            questionorder: questionOrder,
            questionuniqueid: `q-${Date.now()}`,
            optionset: optionSetData ? [optionSetData] : []
        };
    };
    const ques = getDefaultQuestionData('', '', '', '', false, 1)
    const defaultSection = {
        sectionname: "",
        sectionsummary: "",
        questions: [ques]
    };
    const [assessmentData, setassessmentData] = useState({
        assessmentname: "",
        subtitle: "",
        scoreparameter: 1,
        version: 1,
    })

    const [sections, setSections] = useState([JSON.parse(JSON.stringify(defaultSection))]);
    const [summary, setSummary] = useState([]);
    const [defaultSectionIndex, setDefaultSectionIndex] = useState(0);

    const [settingsModal, setSettingsModal] = useState({ show: false, data: {} });
    const [dependencyHolder, setDependencyHolder] = useState({});
    const [constModal, setconstModal] = useState({ show: false, data: {} })
    const [currentIndex, setcurrentIndex] = useState({
        sectionIndex: null,
        qindex: null
    })
    const [menumodal, setmenumodal] = useState("")

    const scrollRef = useRef(null);
    useEffect(() => {
      setmenumodal("")
    
      return () => {
      }
    }, [settingsModal,constModal])
    
    useEffect(() => {
        if (props.location.pathname.includes('edit')) {
            getApi(assessmentDetail + props.match.params.assessmentid).then((response) => {
                console.log(response.data.data,"resp");
                const data = response.data.data;
                setassessmentData({
                    assessmentname: data.assessmentname,
                    subtitle: data.subtitle
                })
                setSections(data.sections);
            });
        }
    }, [])

    const saveAssessmentDetail = (type, event) => {
        setassessmentData(prevState => { return { ...prevState, [type]: event.target.value } })
        // if (type == 'assessmentname') {
        //     setAssessmentName(event.target.value);
        // } else if (type == 'assessmentsubtitle') {
        //     setAssessmentSubtitle(event.target.value);
        // } else if (type == 'assessmentsubject') {
        //     setAssessmentSubject(event.target.value);
        // }
    };

    const openSummaryModal = () => {
        let summaryData = [];
        if ( summary.length == 0 ) {
            summaryData.push(getDetaultSummaryData('', scoringCredit[0].id, true));
        } else {
            summaryData = [...summary];
        }
        // sections.forEach((element, index) => {
        //     if ( typeof summaryData[index + 1] === 'undefined' ) {
        //         summaryData.push(getDetaultSummaryData('', scoringCredit[0].id, false, index));
        //     }
        // });
        setconstModal({
            show: true,
            data: {
                name: "Summary",
                summaryData: summaryData
            }
        })
    };
    const getDetaultSummaryData = (summaryName, basedOn, isOverallSummary, sectionId=null) => {
        let data = {
            summaryname: summaryName,
            basedon: basedOn,
            sectionid: sectionId,
            kpi: [ getDefaultKpiData('') ]
        };
        if ( isOverallSummary ) {
            data['questions'] = sections.map(section => {
                return {
                    name: section.sectionname,
                    options: section.questions.map(question => {
                        return { id: question.questionuniqueid, value: question.questionname, questionname: question.questionname, questionuniqueid: question.questionuniqueid };
                    })
                }
            });
        } else {
            data['questions'] = [
                {
                    name: sections[sectionId].sectionname,
                    options: sections[sectionId].questions.map(question => {
                        return { id: question.questionuniqueid, value: question.questionname, questionname: question.questionname, questionuniqueid: question.questionuniqueid };
                    })
                }
            ];
        }
        return data;
    };
    

    const addSection = () => {
        setSections(prevState => { return [...prevState, ...JSON.parse(JSON.stringify([{ ...defaultSection }]))]; });
        // setDefaultSectionIndex(sections.length)
    };
    const duplicateQuestion = (sectionIndex, qIndex, iswithAns) => {
        setmenumodal("")
        setSections((prevState) => {
            let questionList = prevState[sectionIndex].questions;
            let question = questionList[qIndex]
            if (!iswithAns) {
                let optionSetData = getDefaultOptionSetData(true);
                question = { ...question, optionset: optionSetData ? [optionSetData] : [] }
            }
            questionList.push(question);
            return [...prevState];
        });

    }
    const addQuestion = (sectionIndex) => {
        setSections((prevState) => {
            let questionList = prevState[sectionIndex].questions;
            questionList.push(getDefaultQuestionData('', '', '', '', false, questionList.length + 1));
            return [...prevState];
        });

        // Scrolling to the bottom when question is added
        if (scrollRef.current) {
            scrollRef.current.scrollTo({ behavior: 'smooth', top: scrollRef.current.scrollHeight });
        }
    };

    const addOptionSet = (sectionIndex, qIndex) => {
        setSections((prevState) => {
            let optionSet = prevState[sectionIndex].questions[qIndex].optionset;
            optionSet.push(getDefaultOptionSetData(false, optionSet.length + 1));
            return [...prevState];
        });
    };

    const addOption = (sectionIndex, qIndex, opSetIndex, opindex) => {
        setSections((prevState) => {
            prevState[sectionIndex].questions[qIndex].optionset[opSetIndex].options.splice(opindex + 1, 0, getDefaultOptionData('', ''))
            return [...prevState];
        });
    };

    const deleteSet = (sectionIndex, qIndex, opSetIndex) => {
        setSections((prevState) => {
            prevState[sectionIndex].questions[qIndex].optionset.splice(opSetIndex, 1)
            return [...prevState];
        });
    };

    const deleteQuestion = (sectionIndex, qindex) => {
        setSections((prevState) => {
            prevState[sectionIndex].questions.splice(qindex, 1)
            return [...prevState];
        });
    };

    const deleteSection = (sectionIndex) => {
        let section = [...sections]
        section.splice(sectionIndex, 1)
        setSections(section);
    };

    const onChangeSectionData = (sectionIndex, type, event) => {
        sections[sectionIndex][type] = event.target.value;
        setSections(prevState => { return [...prevState]; });
    };

    const onChangeQuestionData = (name, value, sectionIndex, qIndex) => {
        console.log(name, value, sectionIndex, qIndex)
        setSections(prevState => {
            if (name === 'questiontypename') {
                // Getting the current question
                let question = prevState[sectionIndex].questions[qIndex];

                // Getting question id from question name
                let questionId = questionTypes.find(elem => { return elem.value === value; }).id;

                question['questiontype'] = questionId;

                // Removing optionset for these questions
                if (noOptions.includes(questionId)) {
                    question['optionset'] = [];
                } else if (question['optionset'].length == 0) { // Adding a default answer set if there is no option set
                    question['optionset'] = [getDefaultOptionSetData(true)];
                }
            }
            else if (name === "image") {
                prevState[sectionIndex].questions[qIndex]["image"] = URL.createObjectURL(value)
            } else {
                prevState[sectionIndex].questions[qIndex][name] = value;
            }
            return [...prevState];
        });
    };

    const onChangeOptionData = (name, value, sectionIndex, qIndex, opSetIndex, opIndex) => {
        setSections(prevState => {
            prevState[sectionIndex].questions[qIndex].optionset[opSetIndex].options[opIndex][name] = value;
            return [...prevState];
        });
    };
    const optionDelete = (sectionIndex, qIndex, opSetIndex, opIndex) => {
        setSections(prevState => {
            prevState[sectionIndex].questions[qIndex].optionset[opSetIndex].options.splice(opIndex, 1)

            return [...prevState];
        });
    }

    const onChangeAnswerSet = (sectionIndex, qIndex, opSetIndex, value) => {
        setSections(prevState => {
            prevState[sectionIndex].questions[qIndex].optionset.forEach((element, index) => {
                element['isdefaultset'] = index == opSetIndex ? value : false;
            });
            console.log(prevState[sectionIndex].questions[qIndex].optionset);
            return [...prevState];
        });
    };

    const goToNextAndPreviousSection = (next = false) => {
        if (next) {
            setDefaultSectionIndex(defaultSectionIndex + 1);
        } else {
            setDefaultSectionIndex(defaultSectionIndex - 1);
        }
    };

    const saveAssessment = () => {
        let assessments = assessmentData;
        assessments["sections"] = sections

        console.log(JSON.stringify(assessments), "assessments")
        postApi(assessmentAdd, { data: assessments }).then(response => {
            // console.log(response, "response")
            props.history.goBack();
        }).catch((error) => {
            console.log(error);
        });
    };

    const openSettingsModal = (sIndex, qIndex) => {
        let questionData = JSON.parse(JSON.stringify(sections[sIndex].questions[qIndex]));
        let data = { sectionId: sIndex, questionId: qIndex, ...questionData };
        setSettingsModal(prevState => { return { ...prevState, show: true, data }; });
    };

    const closeSettingsModal = () => {
        setSettingsModal(prevState => { return { ...prevState, show: false, data: {} }; });
    };

    const saveSettings = () => {
        let data = settingsModal.data;

        const sectionData = [...sections];
        let questionData = sectionData[data['sectionId']].questions[data['questionId']];
        questionData.optionset = data.optionset;
        questionData.linkquestion = data.linkquestion;

        setSections(sectionData);

        closeSettingsModal();
    };

    const onSettingTabChange = (key) => {
        if (key == 'link-question') {
            if (typeof settingsModal?.data?.sectionId !== 'undefined' && sections[settingsModal.data.sectionId].questions.length > 0) {
                let questions = JSON.parse(JSON.stringify(sections[settingsModal.data.sectionId].questions));
                questions.splice(settingsModal.data.questionId, 1);

                let linkQuestion = {};
                if (settingsModal.data.linkquestion) {
                    linkQuestion = settingsModal.data.linkquestion;
                } else {
                    linkQuestion['questions'] = questions.map(elem => { return { questionname: elem.questionname, questiontype: elem.questiontype, questionuniqueid: elem.questionuniqueid } });
                }

                setSettingsModal(prevState => {
                    prevState.data.linkquestion = linkQuestion;
                    return { ...prevState };
                });
            }
        }
    };

    const onChangeLinkQuestion = (type, value, index = null) => {
        let linkQuestion = settingsModal.data.linkquestion ? settingsModal.data.linkquestion : {};

        if (type == 'selectallquestions') {
            linkQuestion[type] = value;
            linkQuestion.questions.forEach(element => {
                element['checked'] = value;
            });
        } else if (type == 'question') {
            linkQuestion.questions[index]['checked'] = value;
            linkQuestion['selectallquestions'] = linkQuestion.questions.filter(elem => { return elem.checked; }).length === linkQuestion.questions.length ? true : false;
        } else {
            linkQuestion[type] = value;
        }

        setSettingsModal(prevState => {
            prevState.data.linkquestion = linkQuestion;
            return { ...prevState };
        });
    };
    const attachImage = (res) => {
        console.log(res, "response")
    }

    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.preventDefault();
    }
    const generateAItoQues = () => {
        // postApi(assessmentAdd, { data: assessments }).then(response => {
        //     // console.log(response, "response")
        //     props.history.goBack();
        // }).catch((error) => {
        //     console.log(error);
        // });
    }
    return (
        <div onClick={() => {
            // if(constModal?.show || settingsModal?.show || menumodal != "") {
            //     setconstModal({ show: false, data: {} })
            //     setmenumodal("")
            //     setSettingsModal({ show: false, data: {} })
            // }

        }} className="assessment-add-container" style={{ overflow: "scroll" }}>
            <div className='assessment-header-container d-flex'>
                <img src={`${process.env.PUBLIC_URL}/static/images/editing.png`} alt={"editing"} />
                <div className='d-flex align-items-center m-0 font-weight-bold' style={{ fontSize: '1rem' }}>Craft from scratch</div>
            </div>

            <div className="assessment-container">

                {/** Section Container */}
                <div className="section-container" ref={scrollRef}>
                    <div className="d-flex px-5 w-100">
                        <div className="w-100">
                            <div className="w-100 d-flex align-items-center my-2">
                                <label className="w-30 font-weight-bold " >Assessment Name</label>
                                <div className="w-100">
                                    <TextInput name={'assessmentname'} placeholder={'Assessment Name'} value={assessmentData?.assessmentname}
                                        onChange={(event) => saveAssessmentDetail('assessmentname', event)} ignoreDefaultErrorSpace />
                                </div>
                            </div>
                            <div className="w-100 d-flex align-items-center my-2">
                                <label className="w-30 font-weight-bold">Description</label>
                                <div className="w-100">
                                    <TextInput name={'subtitle'} placeholder={'Subtitle (Optional)'} value={assessmentData?.subtitle}
                                        onChange={(event) => saveAssessmentDetail('subtitle', event)} ignoreDefaultErrorSpace />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex w-30">

                            {/* <div role={"button"} onClick={() => { setmenumodal("upload") }} className="d-flex align-self-start align-items-center position-relative py-2 icons">
                                <img className=" mx-3" width={"20px"} height={"20px"} role={"button"} src={`${process.env.PUBLIC_URL}/static/images/folderg.png`} />
                                Load from Archive
                            </div> */}
                        </div>

                        <Toast className="uploadmodal " show={menumodal == `upload`} onClose={() => { setmenumodal("") }}>
                            <Toast.Header>
                                <div className="d-flex w-100">
                                    <img className=" mx-3" width={"20px"} height={"20px"} role={"button"} src={`${process.env.PUBLIC_URL}/static/images/folderg.png`} />
                                    Select an assessment to load
                                </div>
                            </Toast.Header>
                            <Toast.Body >
                                <div className="d-flex flex-column">
                                    <TextInput name={'search'} placeholder={'Search '} value={""}
                                        onChange={(event) => { }} ignoreDefaultErrorSpace />
                                    <div className="my-2 align-self-center">or</div>
                                    <div className="dropdownview w-100">
                                        <Dropdown onSelect={(eventKey, event) => { }}>
                                            <Dropdown.Toggle size="sm" variant="default" id="dropdown-basic">
                                                {"Select Assessment"}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* {
                                                operators.map((elem, index) => (
                                                    <Dropdown.Item key={"operator" + index} onClick={() => { }}>{elem}</Dropdown.Item>
                                                ))
                                            } */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </Toast.Body>
                        </Toast>
                    </div>
                    <div className="d-flex px-5 w-100 align-items-center">
                        <div className="w-100">
                            <div className="w-100 d-flex align-items-center  my-2">
                                <label className="w-30 font-weight-bold "  >Subject</label>
                                <div className="w-100 d-flex link-question-container align-items-center">
                                    <div className="operator-container">
                                        <Dropdown onSelect={(eventKey, event) => { onChangeLinkQuestion('operator', event.target.innerText); }}>
                                            <Dropdown.Toggle size="sm" variant="default" id="dropdown-basic">
                                                {settingsModal.data?.linkquestion?.operator ? settingsModal.data.linkquestion.operator : "Select Subject"}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {/* {
                                                    operators.map((elem, index) => (
                                                        <Dropdown.Item key={"operator" + index} onClick={() => { }}>{elem}</Dropdown.Item>
                                                    ))
                                                } */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <label className="mx-2 font-weight-bold" >Complexity</label>
                                    <div className="operator-container">

                                        <Dropdown onSelect={(eventKey, event) => { onChangeLinkQuestion('complexity', event.target.innerText); }}>
                                            <Dropdown.Toggle size="sm" variant="default" id="dropdown-basic">
                                                {settingsModal.data?.linkquestion?.complexity ? settingsModal.data.linkquestion.complexity : "Select Complexity"}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {
                                                    complexity.map((elem, index) => (
                                                        <Dropdown.Item key={"operator" + index} onClick={() => { }}>{elem}</Dropdown.Item>
                                                    ))
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="d-flex  justify-content-end icons">
                            <img width={"20px"} height={"20px"} className="mx-2" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/webeye.png`} />
                            <img width={"20px"} height={"20px"} className="mx-2" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/settingb.png`} onClick={() => {
                                setconstModal({
                                    show: true,
                                    data: {
                                        name: "Assessment Settings",
                                    }
                                })
                            }} />
                            <img width={"20px"} height={"20px"} className="mx-2" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/clone.png`} />

                            <img width={"20px"} height={"20px"} className="mx-2" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/delete.png`} onClick={() => { props.history.goBack() }} />
                            <img width={"20px"} height={"20px"} className="mx-2" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/php.png`} />
                        </div>
                    </div>

                    {/** Middle Section */}
                    <div className="middle-section p-4">
                        {/* <div className="button-container">
                            <Button type="button" className="btn btn-primary" onClick={addSection}>
                                <img width="15px" src={`${process.env.PUBLIC_URL}/static/images/plus.png`} alt="plus" />
                                Section
                            </Button>
                            <Button type="button" className="btn btn-primary" onClick={addQuestion}>
                                <img width="15px" src={`${process.env.PUBLIC_URL}/static/images/plus.png`} alt="plus" />
                                Question
                            </Button>
                            <Button type="button" className="btn btn-primary" onClick={() => { }}>
                                <img width="15px" src={`${process.env.PUBLIC_URL}/static/images/plus.png`} alt="plus" />
                                Summary
                            </Button>
                        </div> */}

                        {sections.length > 0 &&
                            sections.map((section, sectionIndex) => (
                                <div className="section">
                                    <div className="d-flex align-items-center">
                                        <label className="section-label font-weight-bold" >Section </label>
                                        <TextInput name={'sectionname'} placeholder={'Section Title'} value={section.sectionname}
                                            onChange={(event) => onChangeSectionData(sectionIndex, 'sectionname', event)} ignoreDefaultErrorSpace />
                                        <div className="d-flex w-50 justify-content-end  align-items-center icons">

                                            <button type="button" className="btn btn-white" onClick={() => { addSection(sectionIndex) }}>
                                                <img className="mx-1" width="12px" src={`${process.env.PUBLIC_URL}/static/images/plusb.png`} alt="plusb" />
                                                <div>Section</div>
                                            </button>
                                            <button type="button" className="btn btn-white" onClick={() => {openSummaryModal() }}>
                                                <img className="mx-1" width="12px" src={`${process.env.PUBLIC_URL}/static/images/plusb.png`} alt="plusb" />
                                                <div>Summary</div>
                                            </button>
                                            <img onClick={() => { deleteSection(sectionIndex) }} width={"20px"} height={"20px"} role={"button"} src={`${process.env.PUBLIC_URL}/static/images/delete.png`} />
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center ">
                                        <label className="section-label font-weight-bold">Description</label>
                                        <TextInput name={'sectionsummary'} placeholder={'Summary (Optional)'} value={section.sectionsummary}
                                            onChange={(event) => onChangeSectionData(sectionIndex, 'sectionsummary', event)} ignoreDefaultErrorSpace />
                                    </div>
                                    <div className="d-flex flex-column w-100">
                                        <div className="d-flex align-items-center   my-3">
                                            <div className="w-100">
                                                <label className="font-weight-bold">Questions</label>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column gap-4">
                                            {
                                                section.questions.length > 0 ?
                                                    section.questions.map((qitem, qindex) => (
                                                        <div key={"question-" + qindex} className="question-container px-3  position-relative">
                                                            <Form.Check custom type="checkbox" name="mandatory" value={qitem.mandatory} onChange={(event) => { onChangeQuestionData(event.target.name, event.target.checked, sectionIndex, qindex); }} />
                                                            <div className="question-title px-2 w-100 ">
                                                                <div className="font-weight-bold">Question - {qindex + 1}</div>
                                                                {/* <div className="w-100" style={{height:"500px"}}> */}
                                                                    <div className="text-editor">
                                                                        <div className="wrapper"><ReactQuill theme="snow"
                                                                            modules={{
                                                                                toolbar: [
                                                                                    [{ size: [] }, { 'font': [] },{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                                    [{ 'color': [] }, { 'background': [] }],
                                                                                    ['bold', 'italic', 'underline'],
                                                                                    [ 'strike', 'blockquote','code-block'],
                                                                                    // [{ 'list': 'ordered' }, { 'list': 'bullet' },
                                                                                    // { 'indent': '-1' }, { 'indent': '+1' }],
                                                                                    // ['clean'],
                                                                                ],
                                                                                clipboard: {
                                                                                    // toggle to add extra line breaks when pasting HTML:
                                                                                    matchVisual: false,
                                                                                }
                                                                            }}
                                                                            placeholder={"Question Name"}
                                                                             value={qitem.questionname} onChange={(value) => { onChangeQuestionData("questionname", value, sectionIndex, qindex) }} />
                                                                        </div>
                                                                    {/* </div> */}
                                                                    
                                                                    {/* <TextInput name={'questionname'} placeholder={'Question Name'} value={qitem.questionname}
                                                                        onChange={(event) => { onChangeQuestionData(event.target.name, event.target.value, sectionIndex, qindex); }} ignoreDefaultErrorSpace /> */}
                                                                </div>
                                                                <div className="d-flex  my-2  align-items-center">
                                                                    <div className="w-90 d-flex gap-2 ">
                                                                        <DropdownInput name='questiontype' defaultValue={'Select a Question Type'} value={qitem.questiontype} options={questionTypes} onChange={(value) => { onChangeQuestionData('questiontypename', value, sectionIndex, qindex); }} />
                                                                        <TextInput name={'score'} placeholder={'Score'} value={qitem.score} type={'number'}
                                                                            onChange={(event) => { onChangeQuestionData(event.target.name, event.target.value, sectionIndex, qindex); }} ignoreDefaultErrorSpace />
                                                                        {/* <Button type="button" className="btn btn-primary" onClick={() => addOptionSet(qindex)}>
                                                                    <img className="mx-1" width="15px" src={`${process.env.PUBLIC_URL}/static/images/plus.png`} alt="plus" />
                                                                    Answer Set
                                                                </Button> */}

                                                                    </div>


                                                                    <div className=" d-flex justify-content-end  align-items-center icons ">
                                                                        <img width={"20px"} height={"20px"} className="mx-2" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/menudots.png`} onClick={() => {
                                                                            setmenumodal(menumodal == `menu ${sectionIndex} ${qindex}` ? "" : `menu ${sectionIndex} ${qindex}`)
                                                                        }} />
                                                                        <Toast className="menumodal " show={menumodal == `menu ${sectionIndex} ${qindex}`} onClose={() => { setmenumodal("") }}>
                                                                            <Toast.Header closeButton={true}> <div className="d-flex w-100" /></Toast.Header>
                                                                            <Toast.Body >
                                                                                <Form.Check custom name="mandatory" type="checkbox" label="Mandatory / Cannot Skip" checked={qitem?.mandatory} onChange={(event) => {
                                                                                    setSections(prevState => {
                                                                                        prevState[sectionIndex].questions[qindex][event.target.name] = event.target.checked;
                                                                                        return [...prevState];
                                                                                    });
                                                                                }} />
                                                                                <Form.Check custom name="qtime" type="checkbox" label="Timed Question" checked={qitem.qtime} onChange={(event) => {
                                                                                    setSections(prevState => {
                                                                                        prevState[sectionIndex].questions[qindex][event.target.name] = event.target.checked;
                                                                                        prevState[sectionIndex].questions[qindex].endAssessment = false
                                                                                        return [...prevState];
                                                                                    });
                                                                                }} />
                                                                                {qitem.qtime && <div>
                                                                                    <div className="d-flex gap-2 my-2">
                                                                                        <TextInput
                                                                                            name={'mins'} placeholder={'mins'} value={qitem?.mins}
                                                                                            onChange={(event) => {
                                                                                                setSections(prevState => {
                                                                                                    prevState[sectionIndex].questions[qindex][event.target.name] = event.target.value;
                                                                                                    return [...prevState];
                                                                                                });
                                                                                            }} ignoreDefaultErrorSpace />
                                                                                        <TextInput
                                                                                            name={'sec'} placeholder={'sec'} value={qitem?.sec}
                                                                                            onChange={(event) => {
                                                                                                setSections(prevState => {
                                                                                                    prevState[sectionIndex].questions[qindex][event.target.name] = event.target.value;
                                                                                                    return [...prevState];
                                                                                                });
                                                                                            }} ignoreDefaultErrorSpace />
                                                                                    </div>
                                                                                    <Form.Check custom name="endAssessment" type="checkbox" label="End assessment" checked={qitem.endAssessment} onChange={(event) => {
                                                                                        setSections(prevState => {
                                                                                            prevState[sectionIndex].questions[qindex][event.target.name] = event.target.checked;
                                                                                            return [...prevState];
                                                                                        });
                                                                                    }} />
                                                                                </div>}
                                                                                <Form.Check custom name="randomize" type="checkbox" label="Randomize Options" checked={qitem.randomize} onChange={(event) => {
                                                                                    setSections(prevState => {
                                                                                        prevState[sectionIndex].questions[qindex][event.target.name] = event.target.checked;
                                                                                        return [...prevState];
                                                                                    });
                                                                                }} />
                                                                                <Form.Check custom name="attachments" type="checkbox" label="Allow Attachments" checked={qitem.attachments} onChange={(event) => {
                                                                                    setSections(prevState => {
                                                                                        prevState[sectionIndex].questions[qindex][event.target.name] = event.target.checked;
                                                                                        return [...prevState];
                                                                                    });
                                                                                }} />
                                                                                <Form.Check custom name="isnegativemark" type="checkbox" label="Add Negative Marking / Penality" checked={qitem.isnegativemark} onChange={(event) => {
                                                                                    setSections(prevState => {
                                                                                        prevState[sectionIndex].questions[qindex][event.target.name] = event.target.checked;
                                                                                        return [...prevState];
                                                                                    });
                                                                                }} />
                                                                                {qitem.isnegativemark && <TextInput type={"number"} max="0"
                                                                                    name={'negscore'} placeholder={'score'} value={qitem?.negscore}
                                                                                    onChange={(event) => {
                                                                                        setSections(prevState => {
                                                                                            prevState[sectionIndex].questions[qindex][event.target.name] = event.target.value;
                                                                                            return [...prevState];
                                                                                        });
                                                                                    }} ignoreDefaultErrorSpace />}
                                                                                <Form.Check custom name="incentive" type="checkbox" label="Add Incentive / Score" checked={qitem.incentive} onChange={(event) => {
                                                                                    setSections(prevState => {
                                                                                        prevState[sectionIndex].questions[qindex][event.target.name] = event.target.checked;
                                                                                        return [...prevState];
                                                                                    });
                                                                                }} />
                                                                                {qitem.incentive && <TextInput type={"number"} min="0"
                                                                                    name={'incentivescore'} placeholder={'score'} value={qitem?.incentivescore}
                                                                                    onChange={(event) => {
                                                                                        setSections(prevState => {
                                                                                            prevState[sectionIndex].questions[qindex][event.target.name] = event.target.value;
                                                                                            return [...prevState];
                                                                                        });
                                                                                    }} ignoreDefaultErrorSpace />}
                                                                                <button type="button" className="btn btn-white "
                                                                                    onClick={() => {
                                                                                        let ans = qitem.optionset.filter(e => e.isdefaultset)
                                                                                        setmenumodal("")
                                                                                        setconstModal({
                                                                                            show: true,
                                                                                            data: {
                                                                                                name: "link",
                                                                                                qustionIndex: qindex,
                                                                                                sectionIndex: sectionIndex,
                                                                                                question: qitem,
                                                                                                linkSet: [{ index: 1 }],
                                                                                                answerSet: ans.length > 0 ? ans[0]?.options : [],
                                                                                                questions: section.questions
                                                                                            }
                                                                                        })
                                                                                    }}>
                                                                                    <div className="link">Link or Conditional Settings</div>
                                                                                </button>
                                                                            </Toast.Body>
                                                                        </Toast>
                                                                        <img width={"20px"} height={"20px"} className="mx-2" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/clone.png`} onClick={() => {
                                                                            setmenumodal(menumodal == `qclone ${sectionIndex} ${qindex}` ? "" : `qclone ${sectionIndex} ${qindex}`)
                                                                        }} />
                                                                        <Toast className="menumodal " show={menumodal == `qclone ${sectionIndex} ${qindex}`} onClose={() => { setmenumodal("") }}>
                                                                            <Toast.Header closeButton={true}> <div className="d-flex w-100" /></Toast.Header>
                                                                            <Toast.Body >
                                                                                <div className="d-flex flex-column">
                                                                                    <button type="button" className="btn btn-white "
                                                                                        onClick={() => { duplicateQuestion(sectionIndex, qindex, false) }}>
                                                                                        <div>Duplicate  question Only</div>
                                                                                    </button>
                                                                                    <button type="button" className="btn btn-white"
                                                                                        onClick={() => { duplicateQuestion(sectionIndex, qindex, true) }}>
                                                                                        <div>Duplicate  with answers</div>
                                                                                    </button>
                                                                                </div>
                                                                            </Toast.Body>
                                                                        </Toast>
                                                                        <img width={"20px"} height={"20px"} className="mx-2" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/attachment.png`} onClick={() => {
                                                                            document.getElementById('image').click();
                                                                            setcurrentIndex({
                                                                                sectionIndex: sectionIndex,
                                                                                qindex: qindex
                                                                            })
                                                                        }} />
                                                                        <Form.Control id="image" name="image" type="file" onChange={(event) => {
                                                                            event.preventDefault();
                                                                            if (event.target.files?.length > 0) {
                                                                                onChangeQuestionData(event.target.name, event.target.files[0], currentIndex.sectionIndex, currentIndex.qindex);
                                                                            }
                                                                        }} accept={IMAGE_TYPES} hidden />
                                                                        <img width={"20px"} height={"20px"} className="mx-2" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/settingb.png`} onClick={() => {
                                                                            setconstModal({
                                                                                show: true,
                                                                                data: {
                                                                                    name: "Setting",
                                                                                    qustionIndex: qindex,
                                                                                    sectionIndex: sectionIndex,
                                                                                    question: qitem,
                                                                                }
                                                                            })
                                                                        }} />
                                                                        <img width={"20px"} height={"20px"} className="mx-2" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/delete.png`} onClick={() => { deleteQuestion(sectionIndex, qindex) }} />
                                                                    </div>

                                                                </div>
                                                                {qitem.image && <div className=" qimage-container">
                                                                    <div className={"qimage"}>

                                                                        <img width={"100%"} height={"100%"} src={qitem.image} />
                                                                    </div>
                                                                    <img className="mx-2" width={"12px"} height={"12px"} role={"button"} src={`${process.env.PUBLIC_URL}/static/images/delete.png`} onClick={() => {
                                                                        setSections((prevState) => {
                                                                            delete prevState[sectionIndex].questions[qindex]["image"]
                                                                            return [...prevState];
                                                                        });

                                                                    }} />
                                                                </div>}
                                                                {/* <TextInput name={'hint'} placeholder={'Hint (Optional)'} value={qitem.hint}
                                                            onChange={(event) => { onChangeQuestionData(event.target.name, event.target.value, qindex); }} ignoreDefaultErrorSpace /> */}

                                                                {/** Option Set and Options Container */}
                                                                <div className="d-flex">

                                                                    {/** Option Set Add Button */}


                                                                    {/** Option Set Container */}
                                                                    <div className=" flex-column gap-5 py-2 mx-2 w-80">
                                                                        {
                                                                            qitem.optionset.map((opset, opsetindex) => (
                                                                                <div className={`d-flex flex-column gap-3 p-1 my-2 ${opsetindex <  qitem?.optionset?.length-1 && "borderui"}`} key={opsetindex} >
                                                                                    <div className="d-flex justify-content-between icons">
                                                                                        <div className="w-30">
                                                                                        <Form.Check size={"lg"} custom className="d-flex gap-1 align-items-center" type="radio" id={`question_${qindex}_optionset_${opsetindex}`} name={`question_${qindex}`} label={`Set ${opsetindex + 1}`} value={opset.isdefaultset} defaultChecked={opset.isdefaultset} onChange={(event) => { onChangeAnswerSet(sectionIndex, qindex, opsetindex, event.target.checked); }} />
                                                                                        </div>
                                                                                        <div className="d-flex flex-column  w-100">
                                                                                            {
                                                                                                opset.options.map((option, opindex) => (
                                                                                                    <div className="d-flex my-1  align-items-center justify-content-center " key={opindex}>
                                                                                                        <div className=" d-flex icons">
                                                                                                            <div className="w-90 d-flex gap-2 ">
                                                                                                                <TextInput name={'optionname'} placeholder={'Option ' + (opindex + 1)} value={option.optionname}
                                                                                                                    onChange={(event) => { onChangeOptionData(event.target.name, event.target.value, sectionIndex, qindex, opsetindex, opindex); }} ignoreDefaultErrorSpace />
                                                                                                                <TextInput name={'score'} placeholder={'Score'} value={option.score} type={'number'}
                                                                                                                    onChange={(event) => { onChangeOptionData(event.target.name, event.target.value, sectionIndex, qindex, opsetindex, opindex); }} ignoreDefaultErrorSpace />
                                                                                                            </div>
                                                                                                            <div className="w-50 d-flex rowcenter  align-items-center">
                                                                                                                <img className="w-10 mx-2 h-5" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/pluscircle.png`} onClick={() => { addOption(sectionIndex, qindex, opsetindex, opindex) }} />
                                                                                                                <img className="w-10 mx-2 h-5" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/crossb.png`} onClick={() => { optionDelete(sectionIndex, qindex, opsetindex, opindex) }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                        <div className=" d-flex align-self-start  align-items-center justify-content-center">
                                                                                            {opset.options.length == 0 && <button type="button" className="btn btn-white d-flex   align-items-center justify-content-center" onClick={() => { addOption(sectionIndex, qindex, opsetindex) }}>
                                                                                                <img className="mx-1" width="12px" src={`${process.env.PUBLIC_URL}/static/images/plusb.png`} alt="plusb" />
                                                                                                <div> Option</div>
                                                                                            </button>}
                                                                                            <img onClick={() => { deleteSet(sectionIndex, qindex, opsetindex); }} height="20px" width="20px" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/delete.png`} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>

                                                                    <button type="button" className="btn btn-white d-flex mx-2  align-items-center justify-content-center rowcenter" onClick={() => { addOptionSet(sectionIndex, qindex) }}>
                                                                        <img className="mx-1" width="20px" src={`${process.env.PUBLIC_URL}/static/images/answerset.png`} alt="plusb" />
                                                                        <div> Answer Set</div>
                                                                    </button>
                                                                    {/* <div className=" justify-content-end align-self-end mx-2 py-5">
                                                                <button type="button" className="btn btn-white d-flex  align-items-center justify-content-center" onClick={() => { addOptionSet(qindex); }} disabled={noOptions.includes(qitem.questiontype)}>
                                                                    <img width="15px" src={`${process.env.PUBLIC_URL}/static/images/plus.png`} alt="plus" />
                                                                    <div>Answer Set</div>
                                                                </button>
                                                            </div> */}
                                                                </div>
                                                            </div>
                                                            {/* <div className="question-actions">
                                                        <img src={`${process.env.PUBLIC_URL}/static/images/settings.png`} alt="settings" onClick={() => { openSettingsModal(defaultSectionIndex, qindex) }} />
                                                        <img src={`${process.env.PUBLIC_URL}/static/images/delete.png`} alt="delete" onClick={() => { deleteQuestion(defaultSectionIndex, qindex) }} />
                                                    </div> */}
                                                        </div>
                                                    )) :
                                                    <div className="px-2">No questions available.</div>
                                            }
                                        </div>
                                        <div className="d-flex  align-items-center align-self-center my-2 rowcenter ">
                                            <button type="button" className="btn btn-grey-light mx-2" onClick={() => { addQuestion(sectionIndex) }}>
                                                <img width="15px" className="mx-1" src={`${process.env.PUBLIC_URL}/static/images/plusb.png`} alt="plusb" />
                                                Question
                                            </button>
                                            <div className="mx-2"> OR</div>
                                            <button type="button" className="btn btn-grey-light" onClick={() => {
                                                setconstModal({
                                                    show: true,
                                                    data: {
                                                        name: "AI",
                                                        sectionIndex: sectionIndex,
                                                    }
                                                })
                                            }}>
                                                <img width="15px" className="mx-1" src={`${process.env.PUBLIC_URL}/static/images/ai.png`} alt="plus" />
                                                AI Generated
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="bottom-section">
                        {/* {sections.length > 0 && <div className="d-flex align-items-center justify-content-center gap-5 no-select">
                            <div className="p-3 cursor-pointer" style={defaultSectionIndex == 0 ? { opacity: 0.5, cursor: 'not-allowed' } : null} onClick={defaultSectionIndex == 0 ? null : () => { goToNextAndPreviousSection(false); }}>
                                <i class="fa fa-less-than"></i>
                            </div>
                            <div>Section {defaultSectionIndex + 1} of {sections.length}</div>
                            <div className="p-3 cursor-pointer" style={sections.length - 1 === defaultSectionIndex ? { opacity: 0.5, cursor: 'not-allowed' } : null} onClick={sections.length - 1 === defaultSectionIndex ? null : () => { goToNextAndPreviousSection(true); }}>
                                <i class="fa fa-greater-than"></i>
                            </div>
                        </div>} */}
                        <div className="d-flex">
                            <div className="d-flex gap-3 rowcenter">
                                <button type="button" className="btn btn-outline-primary" onClick={() => { props.history.goBack(); }}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={saveAssessment}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** Settings Modal */}
            <Modal centered show={constModal.show} size={"lg"} animation={true}>
                <Modal.Header className="modal-header">
                    {constModal?.data?.name == "link" &&
                        <div className="d-flex align-items-center">
                            <img width="15px" className="mx-1" src={`${process.env.PUBLIC_URL}/static/images/link.png`} alt="link" />
                            <div className="modal-head">Link / Conditional Settings</div>
                        </div>}
                    {constModal?.data?.name == "AI" &&
                        <div className="d-flex align-items-center">
                            <img width="15px" className="mx-1" src={`${process.env.PUBLIC_URL}/static/images/ai.png`} alt="ai" />
                            <div className="modal-head">Create question using AI</div>
                        </div>}
                    {constModal?.data?.name == "Setting" &&
                        <div className="d-flex align-items-center">
                            <img width="15px" className="mx-2" src={`${process.env.PUBLIC_URL}/static/images/settings.png`} alt="settings" />
                            <div className="modal-head">Question Settings</div>
                        </div>}
                    {constModal?.data?.name == "Assessment Settings" &&
                        <div className="d-flex align-items-center">
                            <img width="15px" className="mx-2" src={`${process.env.PUBLIC_URL}/static/images/settings.png`} alt="settings" />
                            <div className="modal-head">Assessment Settings</div>
                        </div>}
                        {
                        constModal.data.name === 'Summary' && 
                        <div className="d-flex align-items-center font-weight-bold">Summary</div>
                    }
                    <div className="cursor-pointer close-icon" onClick={() => {
                        setconstModal({
                            show: false,
                            data: {}
                        })
                    }}
                    >X</div>
                </Modal.Header>
                <Modal.Body>
                    {/* {constModal?.data?.name == "clone" &&
                        <div className="w-100  align-items-center justify-content-center ">
                            <div className="  align-items-center justify-content-center my-2 ">
                                <div><label className="modal-tile">Question</label> {constModal?.data?.question.questionname}</div>
                                {constModal?.data?.question.image && <img width={"100px"} height={"100px"} src={constModal?.data?.question.image} />}
                                <div><label className="modal-tile">Type</label>  {constModal?.data?.question.questiontype}</div>
                                <div><label className="modal-tile">Score</label> {constModal?.data?.question.score}</div>
                                <div className="d-flex gap-5">
                                    <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="With answer set" value="with answer set" checked={constModal?.data?.cloneType == "with answer set" ? true : false}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        cloneType: event.target.value
                                                    }
                                                }
                                            });
                                        }} />
                                    <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="Without answer sets" value="without answer set" checked={constModal?.data?.cloneType == "without answer set" ? true : false}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        cloneType: event.target.value
                                                    }
                                                }
                                            });
                                        }} />
                                </div>
                                <div className="d-flex gap-1 align-items-center">
                                    <label className="modal-tile">Select Section Index</label>
                                    <Dropdown onSelect={(eventKey, event) => {
                                        console.log(eventKey)
                                        setconstModal(prevState => {
                                            return {
                                                ...prevState, data: {
                                                    ...prevState.data,
                                                    sectionIndex: eventKey
                                                }
                                            }
                                        });
                                    }}>
                                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                                            {constModal?.data?.sectionIndex >= 0 ? `Section ${constModal?.data?.sectionIndex}` : "Select Section Index"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                sections.map((elem, index) => (
                                                    <Dropdown.Item key={index} eventKey={index} onClick={() => { }}>Section {index}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap">
                                {constModal?.data?.cloneType == "with answer set" &&
                                    constModal?.data?.question.optionset.map((opset, opsetindex) => (
                                        <div className="d-flex flex-column gap-3 p-4 m-2 mx-2 border" key={opsetindex}>
                                            <div className="d-flex">
                                                <div>Answer Set {opsetindex + 1}</div>
                                                {opset.isdefaultset && <div className="defaultUI">{"Default"}</div>}
                                            </div>
                                            {opset.options.map((option, opindex) => (
                                                <div>
                                                    <div><label className="modal-tile">Option</label> {option.optionname}</div>
                                                    <div><label className="modal-tile">Score</label> {option.score}</div>
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="d-flex gap-3  align-items-center justify-content-center ">
                                <button type="button" className="btn btn-outline-primary" onClick={() => {
                                    setconstModal({
                                        show: false,
                                        data: {}
                                    })
                                }}>Cancel</button>
                                <button type="button" className="btn btn-primary" onClick={saveAssessment}>Save</button>
                            </div>
                        </div>
                    } */}
                    {constModal?.data?.name == "AI" &&
                        <div>
                            <div className=" d-flex align-items-center justify-content-center flex-column">
                                <textarea className="text-area" name={'prompt'} label={'Enter Prompt or Paste text'} placeholder={'Enter Prompt or Paste text'} value={constModal?.data?.prompt}
                                    onChange={(event) => {
                                        setconstModal(prevState => {
                                            return {
                                                ...prevState, data: {
                                                    ...prevState.data,
                                                    [event.target.name]: event.target.value
                                                }
                                            }
                                        })
                                    }} />
                                <div className="ortext">OR</div>
                                <div className="upload-component" onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave}
                                    onClick={() => { document.getElementById('media').click(); }}
                                    onDrop={(event) => {
                                        event.preventDefault();
                                        let files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
                                        console.log(files, "files")
                                        if (files?.length > 0) {
                                            // if(files[0]['type'].includes('video/')){
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        media: URL.createObjectURL(files[0])
                                                    }
                                                }
                                            })
                                            // }
                                        }
                                    }}>
                                    <div className="drop-text">Upload Media / Drop Media Here </div>
                                    {/* <div className="my-2">or</div> */}
                                    {/* <Button className="btn btn-primary" onClick={() => { document.getElementById('media').click(); }}>Upload Media</Button> */}
                                    <Form.Control id="media" name="image" type="file" onChange={(event) => {
                                        event.preventDefault();
                                        if (event.target.files?.length > 0) {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        media: URL.createObjectURL(event.target.files[0])
                                                    }
                                                }
                                            })
                                        }

                                    }} accept={FILE + IMAGE_TYPES} hidden />
                                </div>
                            </div>
                            <p className="my-2 font-weight-bold">Choose Question Type to generate</p>
                            <div className="d-flex align-items-center gap-4 ">
                                {questionTypes.map((item) => (
                                    <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label={item?.value} value={item?.value} checked={constModal?.data?.questiontype == item?.value ? true : false}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        questiontype: event.target.value
                                                    }
                                                }
                                            });
                                        }} />
                                ))}
                            </div>
                            <div className="d-flex my-3  align-items-center justify-content-center ">
                                <Button className="btn btn-primary" onClick={() => {generateAItoQues() }}>Generate</Button>
                            </div>
                        </div>
                    }  {constModal?.data?.name == "link" &&
                        <div>
                            {constModal?.data?.linkSet.map((item, index) => (
                                <div className="d-flex my-3 w-100">
                                    <div className="w-90 py-2">
                                        <div className="d-flex align-items-center gap-5 my-3 ">
                                            <DropdownInput size={"lg"} name='whentolink' defaultValue={'Select a when to link'} value={item?.whentolink}
                                                options={linkType}
                                                onChange={(value) => {
                                                    setconstModal(prevState => {
                                                        let linkset = prevState?.data?.linkSet
                                                        linkset[index] = {
                                                            ...item,
                                                            whentolink: value
                                                        }
                                                        return { ...prevState };

                                                    });
                                                }} />
                                            <div className="dropdownview flex-row">
                                                <Dropdown onSelect={(eventKey, event) => {
                                                    setconstModal(prevState => {
                                                        let linkset = prevState?.data?.linkSet
                                                        linkset[index] = {
                                                            ...item,
                                                            answer: event.target.innerText
                                                        }
                                                        return { ...prevState };

                                                    });
                                                }}>
                                                    <Dropdown.Toggle size="sm" variant="default" id="dropdown-basic">
                                                        {item?.answer ? item?.answer : "Select Answer"}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            constModal?.data?.answerSet?.map((elem, index) => (
                                                                <Dropdown.Item key={elem?.optionname} onClick={() => { }}>{elem?.optionname}</Dropdown.Item>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                        </div>
                                        <div className="d-flex align-items-center gap-5 my-3">
                                            <DropdownInput name='questionlinktyp' defaultValue={'Select a when to link'} value={item?.questionlinktyp}
                                                options={questionlinkType}
                                                onChange={(value) => {
                                                    setconstModal(prevState => {
                                                        let linkset = prevState?.data?.linkSet
                                                        linkset[index] = {
                                                            ...item,
                                                            questionlinktyp: value
                                                        }
                                                        return { ...prevState };

                                                    });
                                                }} />
                                            <div className="dropdownview flex-row">
                                                <Dropdown onSelect={(eventKey, event) => {
                                                    setconstModal(prevState => {
                                                        let linkset = prevState?.data?.linkSet
                                                        linkset[index] = {
                                                            ...item,
                                                            question: event.target.innerText
                                                        }
                                                        return { ...prevState };

                                                    });
                                                }}>
                                                    <Dropdown.Toggle size="sm" variant="default" id="dropdown-basic">
                                                        {item?.question ? item?.question : "Select Question"}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            constModal?.data?.questions?.filter(e => e.questionname != constModal?.data?.question?.questionname)?.map((elem, index) => (
                                                                <Dropdown.Item key={elem?.questionname} onClick={() => { }}>{elem?.questionname}</Dropdown.Item>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex my-3 mx-2 flex-column  align-items-center">

                                        <img className=" m-1 " height={"20px"} width={"20px"} role={"button"} src={`${process.env.PUBLIC_URL}/static/images/clone.png`} onClick={() => {
                                            setconstModal(prevState => {
                                                let linkset = prevState?.data?.linkSet
                                                linkset.push(item)
                                                return { ...prevState };

                                            });
                                        }} />
                                        <img className=" m-1 " height={"20px"} width={"20px"} role={"button"} src={`${process.env.PUBLIC_URL}/static/images/delete.png`} onClick={() => {
                                            setconstModal(prevState => {
                                                let linkset = prevState?.data?.linkSet
                                                linkset.splice(index, 1)
                                                return { ...prevState };

                                            });
                                        }}
                                        />
                                        <img className=" m-1 " height={"20px"} width={"20px"} src={`${process.env.PUBLIC_URL}/static/images/plusb.png`} alt="plus" onClick={() => {
                                            setconstModal(prevState => {
                                                let linkset = prevState?.data?.linkSet
                                                linkset.splice(index + 1, 0, { index: linkset.length + 1 })
                                                return { ...prevState };

                                            });
                                        }} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    {constModal?.data?.name == "Setting" &&
                        <div className="gap-5">
                            <div className="d-flex align-items-center gap-5 my-3">
                                {/* <div className="d-flex  gap-3 w-30 align-items-center">
                                    <div>Time Limit: </div>
                                    <TextInput
                                        name={'mins'} placeholder={'mins'} value={constModal?.data?.mins}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        mins: event.target.value
                                                    }
                                                }
                                            });
                                        }} ignoreDefaultErrorSpace />
                                    <TextInput
                                        name={'sec'} placeholder={'sec'} value={constModal?.data?.sec}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        sec: event.target.value
                                                    }
                                                }
                                            });
                                        }} ignoreDefaultErrorSpace />
                                </div> */}
                                <div className="d-flex  gap-3 align-items-center">
                                    <div>Evaluation Type:  </div>
                                    <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="Score" value="Score" checked={constModal?.data?.evaluationType == "Score" ? true : false}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        evaluationType: event.target.value
                                                    }
                                                }
                                            });
                                        }} />
                                    <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="Interpretation" value="Interpretation" checked={constModal?.data?.evaluationType == "Interpretation" ? true : false}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        evaluationType: event.target.value
                                                    }
                                                }
                                            });
                                        }} />
                                    <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="TBD" value="TBD" checked={constModal?.data?.evaluationType == "TBD" ? true : false}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        evaluationType: event.target.value
                                                    }
                                                }
                                            });
                                        }} />
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-5 my-3">
                                {/* <div className="d-flex  gap-3 align-items-center">
                                    <div>Shuffle Choices </div>
                                    <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="Yes" value="Yes" checked={constModal?.data?.shuffleChoices == "Yes" ? true : false}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        shuffleChoices: event.target.value
                                                    }
                                                }
                                            });
                                        }} />
                                    <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="No" value="No" checked={constModal?.data?.shuffleChoices == "No" ? true : false}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        shuffleChoices: event.target.value
                                                    }
                                                }
                                            });
                                        }} />
                                </div> */}
                                {/* <div className="d-flex  gap-3 align-items-center">
                                    <div>Penality/Negative Marking</div>
                                    <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="Yes" value="Yes" checked={constModal?.data?.negativemark == "Yes" ? true : false}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        negativemark: event.target.value
                                                    }
                                                }
                                            });
                                        }} />
                                    <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="No" value="No" checked={constModal?.data?.negativemark == "No" ? true : false}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        negativemark: event.target.value
                                                    }
                                                }
                                            });
                                        }} />
                                </div> */}
                                {constModal?.data?.negativemark == "Yes" && <div className="w-10">
                                    <TextInput
                                        name={'score'} placeholder={'score'} value={constModal?.data?.score}
                                        onChange={(event) => {
                                            setconstModal(prevState => {
                                                return {
                                                    ...prevState, data: {
                                                        ...prevState.data,
                                                        score: event.target.value
                                                    }
                                                }
                                            });
                                        }} ignoreDefaultErrorSpace />
                                </div>}
                            </div>
                        </div>}
                    {constModal?.data?.name == "Assessment Settings" &&
                        <div id={"assessmentsettings"} className={"py-2"}>
                            <Tabs
                                defaultActiveKey="general"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="general" title="General">
                                    <div className="d-flex gap-2 align-items-center justify-content-between">
                                        <div className="d-flex gap-2 align-items-center w-40">
                                            <div className="d-flex gap-2 align-items-center">
                                                <label> Start Date:</label>
                                                <TextInput
                                                    name={'mins'} placeholder={'dd/mm/yyyy'} value={constModal?.data?.startdate}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    startdate: event.target.value
                                                                }
                                                            }
                                                        });
                                                    }} ignoreDefaultErrorSpace />
                                            </div>
                                            <div className="d-flex gap-2 align-items-center">
                                                <label> Time:</label>
                                                <TextInput
                                                    name={'time'} placeholder={'hh:mm'} value={constModal?.data?.time}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    stime: event.target.value
                                                                }
                                                            }
                                                        });
                                                    }} ignoreDefaultErrorSpace />
                                            </div>
                                        </div>
                                        <div className="d-flex gap-2 align-items-center w-40">
                                            <div className="d-flex gap-2 align-items-center">
                                                <label> End Date:</label>
                                                <TextInput
                                                    name={'mins'} placeholder={'dd/mm/yyyy'} value={constModal?.data?.enddate}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    enddate: event.target.value
                                                                }
                                                            }
                                                        });
                                                    }} ignoreDefaultErrorSpace />
                                            </div>
                                            <div className="d-flex gap-2 align-items-center">
                                                <label> Time:</label>
                                                <TextInput
                                                    name={'time'} placeholder={'hh:mm'} value={constModal?.data?.time}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    etime: event.target.value
                                                                }
                                                            }
                                                        });
                                                    }} ignoreDefaultErrorSpace />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center  justify-content-between">
                                        <div className="d-flex gap-2 align-items-center my-3 w-50">
                                            <div className="d-flex gap-2 align-items-center">
                                                <label>Assessment Image:</label>
                                                <div className="assimg"></div>
                                            </div>
                                            <div className="d-flex  gap-3 w-60 align-items-center">
                                                <div>Time Limit: </div>
                                                <TextInput
                                                    name={'mins'} placeholder={'hrs'} value={constModal?.data?.mins}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    mins: event.target.value
                                                                }
                                                            }
                                                        });
                                                    }} ignoreDefaultErrorSpace />
                                                <TextInput
                                                    name={'sec'} placeholder={'mins'} value={constModal?.data?.sec}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    sec: event.target.value
                                                                }
                                                            }
                                                        });
                                                    }} ignoreDefaultErrorSpace />
                                            </div>

                                        </div>
                                        <div className="d-flex  gap-3 align-items-center w-50">
                                            <label>Evaluation Type:  </label>
                                            <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="Score" value="Score" checked={constModal?.data?.evaluationType == "Score" ? true : false}
                                                onChange={(event) => {
                                                    setconstModal(prevState => {
                                                        return {
                                                            ...prevState, data: {
                                                                ...prevState.data,
                                                                evaluationType: event.target.value
                                                            }
                                                        }
                                                    });
                                                }} />
                                            <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="Interpretation" value="Interpretation" checked={constModal?.data?.evaluationType == "Interpretation" ? true : false}
                                                onChange={(event) => {
                                                    setconstModal(prevState => {
                                                        return {
                                                            ...prevState, data: {
                                                                ...prevState.data,
                                                                evaluationType: event.target.value
                                                            }
                                                        }
                                                    });
                                                }} />
                                            <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="TBD" value="TBD" checked={constModal?.data?.evaluationType == "TBD" ? true : false}
                                                onChange={(event) => {
                                                    setconstModal(prevState => {
                                                        return {
                                                            ...prevState, data: {
                                                                ...prevState.data,
                                                                evaluationType: event.target.value
                                                            }
                                                        }
                                                    });
                                                }} />
                                        </div>

                                    </div>
                                    <div className="d-flex  gap-3 my-3 align-items-center">
                                        <label>Assessment Instructions:</label>
                                        <div className="w-50 instruction-textarea d-flex">
                                            <textarea className=" w-100" name={'instruction'} label={'Type or Upload Instructions.'} placeholder={'Type or Upload Instructions.'} value={constModal?.data?.instruction}
                                                onChange={(event) => {
                                                    setconstModal(prevState => {
                                                        return {
                                                            ...prevState, data: {
                                                                ...prevState.data,
                                                                [event.target.name]: event.target.value
                                                            }
                                                        }
                                                    })
                                                }} />
                                            <img className="w-5 mx-2 h-5 align-self-center" role={"button"} src={`${process.env.PUBLIC_URL}/static/images/attachment.png`} onClick={() => {
                                                document.getElementById('instruction').click();

                                            }} />
                                            <Form.Control id="instruction" name="image" type="file" onChange={(event) => {
                                                event.preventDefault();
                                                if (event.target.files?.length > 0) {
                                                    onChangeQuestionData(event.target.name, event.target.files[0], currentIndex.sectionIndex, currentIndex.qindex);
                                                }
                                            }} accept={FILE + IMAGE_TYPES} hidden />
                                        </div>
                                    </div>
                                    <div className="d-flex gap-2 justify-content-between">
                                        <div className="w-50">
                                            <div className="d-flex gap-2 justify-content-between">

                                                <Form.Check custom className="d-flex gap-1 align-items-center" type="checkbox" label="Randomize Questions" checked={constModal?.data?.randomizeQuestions}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    randomizeQuestions: constModal?.data?.randomizeQuestions ? !constModal?.data?.randomizeQuestions : true
                                                                }
                                                            }
                                                        });
                                                    }} />
                                                <Form.Check custom className="d-flex gap-1 w-50 align-items-center" type="checkbox" label="Randomize answer set" checked={constModal?.data?.randomizeAnswerSet}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    randomizeAnswerSet: constModal?.data?.randomizeAnswerSet ? !constModal?.data?.randomizeAnswerSet : true
                                                                }
                                                            }
                                                        });
                                                    }} />
                                            </div>

                                            <div className="d-flex gap-2 justify-content-between">

                                                <Form.Check custom className="d-flex gap-1 align-items-center" type="checkbox" label="Shuffle choices" checked={constModal?.data?.shuffleChoices}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    shuffleChoices: constModal?.data?.shuffleChoices ? !constModal?.data?.shuffleChoices : true
                                                                }
                                                            }
                                                        });
                                                    }} />
                                                <Form.Check custom className="d-flex  w-50 gap-1 align-items-center" type="checkbox" label="Allow multiple answers" checked={constModal?.data?.multipleAns}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    multipleAns: constModal?.data?.multipleAns ? !constModal?.data?.multipleAns : true
                                                                }
                                                            }
                                                        });
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="w-50">
                                            <div className="d-flex gap-2 justify-content-between">

                                                <Form.Check custom className="d-flex gap-1 align-items-center" type="checkbox" label="Show Progress Bar" checked={constModal?.data?.showProgressBar}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    showProgressBar: constModal?.data?.showProgressBar ? !constModal?.data?.showProgressBar : true
                                                                }
                                                            }
                                                        });
                                                    }} />
                                                <Form.Check custom className="d-flex gap-1 w-50 align-items-center" type="checkbox" label="End test on time out" checked={constModal?.data?.timeout}
                                                    onChange={(event) => {
                                                        setconstModal(prevState => {
                                                            return {
                                                                ...prevState, data: {
                                                                    ...prevState.data,
                                                                    timeout: constModal?.data?.timeout ? !constModal?.data?.timeout : true
                                                                }
                                                            }
                                                        });
                                                    }} />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="d-flex gap-5 ">

                                        <Form.Check custom className="d-flex gap-1 align-items-center" type="checkbox" label="Allow response edit" checked={constModal?.data?.allowedit}
                                            onChange={(event) => {
                                                setconstModal(prevState => {
                                                    return {
                                                        ...prevState, data: {
                                                            ...prevState.data,
                                                            allowedit: constModal?.data?.allowedit ? !constModal?.data?.allowedit : true
                                                        }
                                                    }
                                                });
                                            }} />
                                        <Form.Check custom className="d-flex gap-1   align-items-center" type="checkbox" label="Allow reattempts" checked={constModal?.data?.allowattempts}
                                            onChange={(event) => {
                                                setconstModal(prevState => {
                                                    return {
                                                        ...prevState, data: {
                                                            ...prevState.data,
                                                            allowattempts: constModal?.data?.allowattempts ? !constModal?.data?.allowattempts : true
                                                        }
                                                    }
                                                });
                                            }} />
                                        {constModal?.data?.allowattempts && <div className="d-flex gap-2">
                                            <label>No of Attempts</label>
                                            <TextInput
                                                name={'count'} placeholder={'Count'} value={constModal?.data?.count}
                                                onChange={(event) => {
                                                    setconstModal(prevState => {
                                                        return {
                                                            ...prevState, data: {
                                                                ...prevState.data,
                                                                count: event.target.value
                                                            }
                                                        }
                                                    });
                                                }} ignoreDefaultErrorSpace />
                                        </div>}
                                    </div>
                                    {/* <div className="d-flex  gap-3 my-3 ">
                                        <div className="d-flex  gap-3 align-items-center">
                                            <div>Randomize Questions: </div>
                                            <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="Yes" value="Yes" checked={constModal?.data?.randomizeQuestions == "Yes" ? true : false}
                                                onChange={(event) => {
                                                    setconstModal(prevState => {
                                                        return {
                                                            ...prevState, data: {
                                                                ...prevState.data,
                                                                randomizeQuestions: event.target.value
                                                            }
                                                        }
                                                    });
                                                }} />
                                            <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="No" value="No" checked={constModal?.data?.randomizeQuestions == "No" ? true : false}
                                                onChange={(event) => {
                                                    setconstModal(prevState => {
                                                        return {
                                                            ...prevState, data: {
                                                                ...prevState.data,
                                                                randomizeQuestions: event.target.value
                                                            }
                                                        }
                                                    });
                                                }} />
                                        </div>
                                        <div className="d-flex  gap-3 align-items-center">
                                            <div>Shuffle Choices </div>
                                            <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="Yes" value="Yes" checked={constModal?.data?.shuffleChoices == "Yes" ? true : false}
                                                onChange={(event) => {
                                                    setconstModal(prevState => {
                                                        return {
                                                            ...prevState, data: {
                                                                ...prevState.data,
                                                                shuffleChoices: event.target.value
                                                            }
                                                        }
                                                    });
                                                }} />
                                            <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="No" value="No" checked={constModal?.data?.shuffleChoices == "No" ? true : false}
                                                onChange={(event) => {
                                                    setconstModal(prevState => {
                                                        return {
                                                            ...prevState, data: {
                                                                ...prevState.data,
                                                                shuffleChoices: event.target.value
                                                            }
                                                        }
                                                    });
                                                }} />
                                        </div>

                                    </div>

                                    <div className="d-flex  gap-3 align-items-center">
                                        <div>Allow Response/Answer Edit </div>
                                        <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="Yes" value="Yes" checked={constModal?.data?.answerEdit == "Yes" ? true : false}
                                            onChange={(event) => {
                                                setconstModal(prevState => {
                                                    return {
                                                        ...prevState, data: {
                                                            ...prevState.data,
                                                            answerEdit: event.target.value
                                                        }
                                                    }
                                                });
                                            }} />
                                        <Form.Check custom className="d-flex gap-1 align-items-center" type="radio" label="No" value="No" checked={constModal?.data?.answerEdit == "No" ? true : false}
                                            onChange={(event) => {
                                                setconstModal(prevState => {
                                                    return {
                                                        ...prevState, data: {
                                                            ...prevState.data,
                                                            answerEdit: event.target.value
                                                        }
                                                    }
                                                });
                                            }} />

                                    </div> */}

                                </Tab>
                                <Tab eventKey="proctoring" title="Proctoring">
                                    Tab content for Proctoring
                                </Tab>
                                <Tab eventKey="proxyValidator" title="Proxy Validator">
                                    Tab content for Contact
                                </Tab>
                            </Tabs>
                        </div>}
                        {
                        constModal.data.name === 'Summary' &&
                        <div className="d-flex flex-column">
                            <Tabs id="summary-tab" defaultActiveKey={-1} onSelect={() => {}}>
                                
                                {/** Overall level summary */}
                                {/** TODO Backend need to be handled for supporting multiple overall summaries. Same need to handled for section summary. */}
                                <Tab eventKey={-1} title="Overall Summary">
                                    <AssessmentSummaryHOC data={constModal.data.summaryData[0]} />
                                </Tab>
                                {/** TODO Section level summary will be enabled later after clearing the doubts */}
                                {/* {
                                    sections.map((section, index) => (
                                        <Tab eventKey={index} title={'Section ' + (index + 1)}>
                                            <AssessmentSummaryHOC data={constModal.data.summaryData[index + 1]} />
                                        </Tab>
                                    ))
                                } */}
                            </Tabs>
                            <div className="d-flex align-items-center justify-content-center flex-row mt-4">
                                <Button variant="outline-dark btn-white mx-2" style={{ width: '120px' }} onClick={() => { 
                                    setconstModal({
                                        show: false,
                                        data: {}
                                    })
                                 }}>Cancel</Button>
                                <Button className="btn btn-primary mx-2" style={{ width: '120px' }} onClick={() => {
                                    setSummary(constModal.data.summaryData);
                                    setconstModal({
                                        show: false,
                                        data: {}
                                    })
                                }}>Save</Button>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
            <Modal centered show={settingsModal.show} size="lg" animation={true}>
                <Modal.Header className="modal-header">
                    <div>{settingsModal.data.questionname}</div>
                    <div className="cursor-pointer close-icon" onClick={closeSettingsModal}>X</div>
                </Modal.Header>
                <Modal.Body>
                    <Tabs id="settings-tab" defaultActiveKey="answer-set" onSelect={onSettingTabChange}>
                        {/** Answer Set Tab */}
                        {/* <Tab eventKey="answer-set" title="Answer Set">
                            <div className="settings-container p-10">
                                <div className="answer-set-add-container">
                                    <button type="button" className={`btn btn-primary`} onClick={addOptionSet}>
                                        <img className="w-15" src={`${process.env.PUBLIC_URL}/static/images/plus.png`} alt="plus" />Answer set
                                    </button>
                                </div>
                                <div className="answer-set-container">
                                    {
                                        settingsModal.data.optionset?.map((opset, opsetindex) => (
                                            <div className="answer-set" key={opsetindex}>
                                                <div className="answer-set-control-container">
                                                    <Form.Check custom className="display-flex h-center v-center" style={{width: "5%", gap: "5px", padding: 0}} type="radio" id={"optionset" + opsetindex} name="option" label={`Set ${opsetindex + 1}`} checked={opset.isdefaultset} onChange={(event) => { onChangeAnswerSet(opsetindex); }} />
                                                    <div className="answer-set-input-container">
                                                        <TextInput className="option-name" name={'optionname'} placeholder={'Option Name'} value={opset.temp_option_name} onChange={(event) => { onChangeOptionData('temp_option_name', opsetindex, event); }} />
                                                        <TextInput className="option-score" name={'score'} placeholder={'Score'} value={opset.temp_option_score} onChange={(event) => { onChangeOptionData('temp_option_score', opsetindex, event); }} />
                                                    </div>
                                                    <div className="answer-action-container">
                                                        <button type="button" className="btn btn-primary" onClick={() => { addOption(opsetindex); }} disabled={!opset.temp_option_name || !opset.temp_option_score}>+</button>
                                                    </div>
                                                    <div className="answer-action-container">
                                                        <button type="button" className="btn btn-danger" onClick={() => { clearOption(opsetindex); }}>x</button>
                                                    </div>
                                                </div>
                                                <div className="set-option-container">
                                                    {
                                                        opset.options.map((option, opindex) => (
                                                            <div className="set-option" key={opindex}>
                                                                <div className="set-option-name-score">
                                                                    <div>{option.optionname}</div>
                                                                    <div className="set-option-score">{option.score}</div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="answer-set-action-container">
                                    <Button variant="outline-dark btn-white" onClick={closeSettingsModal}>Cancel</Button>
                                    <Button className="btn btn-primary" onClick={saveSettings}>Save</Button>
                                </div>
                            </div>
                        </Tab> */}

                        {/** Link Question Tab */}
                        <Tab eventKey="link-question" title="Link Question">
                            <div className="d-flex justify-content-center align-items-center link-question-container">
                                <div className="operator-container">
                                    <label>Operator</label>
                                    <Dropdown onSelect={(eventKey, event) => { onChangeLinkQuestion('operator', event.target.innerText); }}>
                                        <Dropdown.Toggle size="sm" variant="default" id="dropdown-basic">
                                            {settingsModal.data?.linkquestion?.operator ? settingsModal.data.linkquestion.operator : "Select Operator"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {
                                                operators.map((elem, index) => (
                                                    <Dropdown.Item key={"operator" + index} onClick={() => { }}>{elem}</Dropdown.Item>
                                                ))
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                <TextInput className="link-question-score" name={'score'} label={'Score'} type={'number'} placeholder={'Score'} value={settingsModal.data?.linkquestion?.score ? settingsModal.data.linkquestion.score : ""} onChange={(event) => { onChangeLinkQuestion('score', event.target.value); }} />

                                {
                                    Array.isArray(settingsModal?.data?.linkquestion?.questions) ?
                                        <Form className="link-questions-all-container">
                                            <label>Questions</label>
                                            {
                                                settingsModal.data.linkquestion.questions.length > 0 ?
                                                    <>
                                                        <Form.Check className="no-select" type="checkbox" name="select-all-questions" id="select-all-questions" label="Select All Questions" value="Select All Questions" checked={settingsModal.data?.linkquestion?.selectallquestions ? true : false} onChange={(event) => { onChangeLinkQuestion('selectallquestions', event.target.checked); }} />
                                                        <div className="link-questions-all">
                                                            {
                                                                settingsModal.data.linkquestion.questions.map((question, index) => (
                                                                    <Form.Check key={"all-questions" + index} className="no-select" type="checkbox" name={"all-questions" + index} id={"all-questions" + index} label={question.questionname} value={question.questionname} checked={question.checked ? true : false} onChange={(event) => { onChangeLinkQuestion('question', event.target.checked, index); }} />
                                                                ))
                                                            }
                                                        </div>
                                                    </> :
                                                    <div>No questions available to configure dependency.</div>
                                            }
                                        </Form> : null
                                }

                                <div className="link-question-action-container">
                                    <Button variant="outline-dark btn-white" onClick={closeSettingsModal}>Cancel</Button>
                                    <Button className="btn btn-primary" onClick={saveSettings}>Save</Button>
                                </div>

                            </div>
                        </Tab>

                        {/** Proxy Validator Tab */}
                        <Tab eventKey="proxy-validator" title="Proxy Validator">
                            Proxy Validator Tab In Progress
                        </Tab>

                        {/** Result Tab */}
                        <Tab eventKey="result" title="Result">
                            Result Tab In Progress
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>

        </div>
    )
}
const AssessmentSummaryHOC = (props) => {
    const [summaryData, setSummaryData] = useState(props.data ? props.data : {});
    const onChangeSummaryData = (name, value, kpiIndex=null, configurationIndex=null) => {
        setSummaryData(prevState => {
            if ( name === 'indicatorname' ) {
                prevState.kpi[kpiIndex][name] = value;
            } else if ( name === 'operator' || name === 'score' || name === 'displayname' || name === 'color' ) {
                prevState.kpi[kpiIndex].configuration[configurationIndex][name] = value;
            } else {
                prevState[name] = value;
            }
            return {...prevState};
        });
    };
    const addKpi = () => {
        setSummaryData(prevState => {
            prevState.kpi.push(getDefaultKpiData(''));
            return {...prevState};
        })
    };
    const addKpiConfiguration = (kpiIndex) => {
        setSummaryData(prevState => {
            prevState.kpi[kpiIndex].configuration.push(getDefaultKpiConfigurationData('', '', ''));
            return {...prevState};
        })
    };
    const deleteKpiConfiguration = (kpiIndex, configurationIndex) => {
        setSummaryData(prevState => {
            prevState.kpi[kpiIndex].configuration.splice(configurationIndex, 1);
            return {...prevState};
        })
    };
    return (
        <div className="d-flex flex-column gap-3 p-4">
            <div className="d-flex">
                <Form.Label className="d-flex w-20 fw-bold align-items-center m-0">Summary Name</Form.Label>
                <TextInput name={'summaryname'} placeholder={'Summary Name'} value={summaryData.summaryname || ''}
                    onChange={(event) => { onChangeSummaryData(event.target.name, event.target.value); }} ignoreDefaultErrorSpace />
            </div>
            <div className="d-flex">
                <Form.Label className='w-20 fw-bold align-items-center m-0'>Based On</Form.Label>
                <Form className="d-flex align-items-center gap-3">
                    {
                        scoringCredit.map((option) => (
                            <Form.Check type="radio" name={"overall-based-on"} id={"overall-based-on-" + option.id} label={option.value} value={option.id} defaultChecked={summaryData.basedon ? summaryData.basedon == option.id : false} onChange={(event) => { onChangeSummaryData('basedon', event.target.value); }} />
                        ))
                    }
                </Form>
            </div>
            <div className="d-flex">
                <Form.Label className='w-20 fw-bold align-items-center m-0'>Questions</Form.Label>
                <DropdownInputGroup multiple placeholder="Select Questions" data={summaryData.questions} onChange={(data) => { onChangeSummaryData('questions', data); }}/>
            </div>
            <div className="d-flex flex-column gap-3">
                <div className="d-flex justify-content-between">
                    <Form.Label className='fw-bold d-flex align-items-center m-0'>Key Performance Indicator</Form.Label>
                    <button className="btn btn-white" onClick={addKpi}>
                        <img className="mx-1" width="12px" src={`${process.env.PUBLIC_URL}/static/images/plusb.png`} alt="plusb" />
                        <div>KPI</div>
                    </button>
                </div>
                {
                    summaryData.kpi.map((kpi, kpiIndex) => (
                        <div className="kpi d-flex flex-column gap-2" key={'kpi-' + kpiIndex}>
                            <div className="d-flex">
                                <Form.Label className='w-20 fw-bold d-flex align-items-center m-0'>Indicator Name</Form.Label>
                                <TextInput name={'indicatorname'} placeholder={'Indicator Name'} value={kpi.indicatorname || ''}
                                    onChange={(event) => { onChangeSummaryData(event.target.name, event.target.value, kpiIndex); }} ignoreDefaultErrorSpace />
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <Form.Label className='d-flex align-items-center fw-bold m-0'>Configuration</Form.Label>
                                <div className="d-flex flex-column gap-3" style={{backgroundColor: '#f0f0f0', padding: '10px 20px'}}>
                                    {
                                        kpi.configuration.map((configuration, configurationIndex) => (
                                            <div className="d-flex gap-3" key={`kpi-${kpiIndex}-configuration-${configurationIndex}`}>
                                                <Form.Label className='w-20 fw-bold d-flex align-items-center m-0'>If { scoringCredit.find(elem => { return elem.id == summaryData.basedon })?.value?.toLowerCase() } is</Form.Label>
                                                <DropdownInput name='operator' defaultValue={'Select Operator'} value={configuration.operator} options={operators} style={{flex: '4 0'}}
                                                    onChange={(value) => { onChangeSummaryData('operator', value, kpiIndex, configurationIndex); }} />
                                                <TextInput name={'score'} placeholder={'Score'} value={configuration.score} style={{flex: '2 0'}}
                                                    onChange={(event) => { onChangeSummaryData(event.target.name, event.target.value, kpiIndex, configurationIndex); }} ignoreDefaultErrorSpace />
                                                <TextInput name={'displayname'} placeholder={'Display Name'} value={configuration.displayname} style={{flex: '3 0'}}
                                                    onChange={(event) => { onChangeSummaryData(event.target.name, event.target.value, kpiIndex, configurationIndex); }} ignoreDefaultErrorSpace />
                                                <TextInput type='color' name={'color'} placeholder={'Color'} value={configuration.color} style={{flex: '1 0'}}
                                                    onChange={(event) => { onChangeSummaryData(event.target.name, event.target.value, kpiIndex, configurationIndex); }} ignoreDefaultErrorSpace />
                                                <div className="kpi-config-delete">
                                                    <img className={kpi.configuration.length > 1 ? '' : 'disabled'} role={"button"} src={`${process.env.PUBLIC_URL}/static/images/delete.png`} onClick={kpi.configuration.length > 1 ? () => { deleteKpiConfiguration(kpiIndex, configurationIndex); } : null} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="d-flex p-2 align-items-center justify-content-center">
                                    <button type="button" className="btn btn-grey-light mx-2" onClick={() => { addKpiConfiguration(kpiIndex); }}>
                                        <img width="15px" className="mx-1" src={`${process.env.PUBLIC_URL}/static/images/plusb.png`} alt="plusb" />
                                        Configuration
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};